import { Fragment, useEffect } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Dialog, Transition } from "@headlessui/react";
import { useSession } from "next-auth/react";

import Icon from "@/components/atoms/Icon";
import { MobileMenuLink } from "@/components/atoms/MobileMenuLink";
import { LanguageSwitcher } from "@/components/molecules/LanguageSwitcher";
import { SubNavGroup } from "@/components/molecules/SubNavGroup";
import { ToggleAudience } from "@/components/molecules/ToggleAudience";
import { MenuFieldsFragment } from "@/graphql/sdk/__generated__";
import { useTranslation } from "@/i18n/client";
import type { AllowedLanguages } from "@/types/generic";

interface MobileMenuProps {
  locale: AllowedLanguages;
  mainMenu: MenuFieldsFragment;
  mobileMenuOpen: boolean;
  secondaryMenu: MenuFieldsFragment;
  setMobileMenuOpen: (open: boolean) => void;
}

export const MobileMenu = ({
  mobileMenuOpen,
  mainMenu,
  secondaryMenu,
  setMobileMenuOpen,
  locale,
}: MobileMenuProps) => {
  const pathname = usePathname();
  const { t } = useTranslation(locale);
  const { data: session } = useSession();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname, setMobileMenuOpen]);

  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 xl:hidden"
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-150 sm:duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-150 sm:duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between gap-4 border-b pb-4">
              <div className="flex flex-wrap items-center gap-x-4">
                <span>{t("header.toggle_audience")}</span>
                <ToggleAudience
                  className=""
                  i18n={{
                    employee: t("header.employee"),
                    employer: t("header.employer"),
                  }}
                  locale={locale}
                />
              </div>

              <button
                type="button"
                className="ml-auto text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <Icon name="close" className="h-5 w-5 text-black-300" />
              </button>
            </div>

            <div className="mt-4 flow-root">
              <div className="flex flex-col gap-3">
                {mainMenu?.items?.map((item, index) => (
                  <Fragment key={item.id}>
                    {item.children?.length ? (
                      <SubNavGroup
                        itemTitle={item.title ?? ""}
                        itemUrl={item.url}
                        subNav={item.children}
                        linkClassName="text-sm"
                        subNavClassName="pt-3"
                        clickToOpen={true}
                      />
                    ) : (
                      <MobileMenuLink item={item} />
                    )}
                  </Fragment>
                ))}
              </div>

              {!session && (
                <div className="mt-6 rounded-lg bg-yellow-600 px-4 py-6 text-center">
                  <div className={"mb-2 text-lg font-medium text-black"}>
                    {t("header.my_vivaldis.title")}
                  </div>
                  <div className="text-balance mb-6 px-2 text-sm text-black-400">
                    {t("header.my_vivaldis.message")}
                  </div>
                  <div className="flex justify-center gap-2">
                    <Link
                      href={`/${locale}/myvivaldis`}
                      className="btn btn-green btn-sm"
                    >
                      {t("header.login")}
                    </Link>
                    <Link
                      href={`/${locale}/myvivaldis`}
                      className="btn btn-white btn-outline btn-sm"
                    >
                      {t("header.register")}
                    </Link>
                  </div>
                </div>
              )}

              <div className="grid grid-cols-2 gap-3 py-6">
                {secondaryMenu?.items?.map((item) => (
                  <MobileMenuLink item={item} key={item.id} />
                ))}
              </div>

              <LanguageSwitcher locale={locale} direction="up" />
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
