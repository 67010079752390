import Link from "next/link";
import { usePathname } from "next/navigation";

import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface MobileMenuLinkProps {
  className?: string;
  item: {
    title: string;
    url: string;
  };
}

export const MobileMenuLink = ({ item, className }: MobileMenuLinkProps) => {
  const pathname = usePathname();

  return (
    <Link
      href={item.url}
      className={cn(
        `inline-flex items-center gap-2 rounded-lg text-base leading-none transition-colors hover:text-black`,
        pathname === item.url ? "text-green" : "text-black-400",
        className ?? "",
      )}
    >
      {item.extras && item.extras.iconSelect && (
        <span className="inline-flex h-10 w-10 min-w-[2.5rem] items-center justify-center rounded border bg-grey-100">
          <Icon name={item.extras.iconSelect} className="h-5 w-5" />
        </span>
      )}
      {item.title}
    </Link>
  );
};
