"use client";

import { Fragment, useState } from "react";
import Link from "next/link";
import { Transition } from "@headlessui/react";
import { Session } from "next-auth";
import { signOut } from "next-auth/react";
import {
  applicationsPaths,
  availabilityPaths,
  documentsPaths,
  messagesPaths,
  preferencesPaths,
  profilePaths,
} from "routes";

import Button from "@/components/atoms/Button";
import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";
import { useTranslation } from "@/i18n/client";
import { AllowedLanguages } from "@/types/generic";

interface AccountNavBtnProps {
  locale: AllowedLanguages;
  session: Session;
}

export const AccountNavBtn = ({ locale, session }: AccountNavBtnProps) => {
  const { t } = useTranslation(locale);
  const [isOpen, setIsOpen] = useState(false);

  const nav = [
    {
      title: t("account.dashboard"),
      url: `/${locale}/myvivaldis/dashboard`,
    },
    {
      title: t("account.messages"),
      url: `/${locale}/myvivaldis/${messagesPaths[locale]}`,
    },
    {
      title: t("account.profile"),
      url: `/${locale}/myvivaldis/${profilePaths[locale]}`,
    },
    {
      title: t("account.availability"),
      url: `/${locale}/myvivaldis/${availabilityPaths[locale]}`,
    },
    {
      title: t("account.saved_jobs"),
      url: `/${locale}/myvivaldis/saved-jobs`,
    },
    {
      title: t("account.documents"),
      url: `/${locale}/myvivaldis/${documentsPaths[locale]}`,
    },
    {
      title: t("account.applications"),
      url: `/${locale}/myvivaldis/${applicationsPaths[locale]}`,
    },
  ];

  const preferences = {
    title: t("account.preferences"),
    url: `/${locale}/myvivaldis/${preferencesPaths[locale]}`,
  };

  return (
    <div
      className={cn(
        "has-sub-nav relative flex items-center",
        isOpen ? "is-active" : "",
      )}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {/* Mobile */}
      <Link href={`/${locale}/myvivaldis/dashboard`} className="xl:!hidden">
        <Icon name="profile" className="h-8 w-8" />
      </Link>

      {/* Desktop */}
      <Button
        as="link"
        href={`/${locale}/myvivaldis/dashboard`}
        className="btn-yellow btn-lg !hidden pl-3 pr-4 text-lg font-medium xl:!inline-flex"
        arrow={false}
      >
        <Icon name="profile" className="h-8 w-8" />
        <span className="">{session.user.name}</span>
        <Icon name="angle-down" className="h-5 w-5" />
      </Button>

      <Transition
        as={Fragment}
        show={isOpen}
        unmount={false}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <ul
          className={cn(
            "sub-nav absolute -right-4 top-full z-10 min-w-full rounded-lg bg-white px-4 py-4 shadow-lg lg:py-6 xl:left-0 xl:right-auto",
          )}
        >
          {nav.map((item) => (
            <li key={item.title}>
              <Link
                href={item.url}
                className="block whitespace-nowrap font-medium text-black-400 transition-colors hover:text-yellow-600 lg:py-0.5 lg:text-lg"
              >
                {item.title}
              </Link>
            </li>
          ))}
          <hr className="my-1" />
          <li>
            <Link
              href={preferences.url}
              className="flex items-center py-0.5 text-black-300 transition-colors hover:text-yellow-600 lg:py-1"
            >
              <Icon
                name={"gear"}
                className={"mr-3 hidden h-5 w-5 text-green md:inline-flex"}
              />
              {preferences.title}
            </Link>
            <button
              type="button"
              className="flex items-center py-0.5 text-black-300 transition-colors hover:text-yellow-600 lg:py-1"
              onClick={() => signOut()}
            >
              <Icon
                name={"sign-out"}
                className={"mr-3 hidden h-5 w-5 text-negative md:inline-flex"}
              />
              {t("account.logout")}
            </button>
          </li>
        </ul>
      </Transition>
    </div>
  );
};
