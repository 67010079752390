"use client";

import Image from "next/image";
import Link from "next/link";

import logo from "@/assets/images/logo-vivaldis.svg";
import { FooterLoginSection } from "@/components/blocks/FooterLoginSection";
import { FooterNavSection } from "@/components/blocks/FooterNavSection";
import { FooterSocialLinks } from "@/components/blocks/FooterSocialLinks";
import { LanguageSwitcher } from "@/components/molecules/LanguageSwitcher";
import { SubNavGroup } from "@/components/molecules/SubNavGroup";
import { MenuFieldsFragment } from "@/graphql/sdk/__generated__";
import { useTranslation } from "@/i18n/client";
import { useTargetAudience } from "@/store/targetAudience";
import { AllowedLanguages, AvailableTargetAudience } from "@/types/generic";

interface FooterProps {
  locale: AllowedLanguages;
  menuAbout?: MenuFieldsFragment;
  menuEmployee?: MenuFieldsFragment;
  menuEmployer?: MenuFieldsFragment;
  menuExpertises?: MenuFieldsFragment;
  menuJobsPerRegion?: MenuFieldsFragment;
  menuJobsPerSector?: MenuFieldsFragment;
  menuLegal?: MenuFieldsFragment;
  menuServices?: MenuFieldsFragment;
}

export const Footer = ({
  locale,
  menuAbout,
  menuEmployee,
  menuEmployer,
  menuExpertises,
  menuJobsPerRegion,
  menuJobsPerSector,
  menuServices,
  menuLegal,
}: FooterProps) => {
  const { targetAudience } = useTargetAudience();
  const { t } = useTranslation(locale);

  return (
    <footer
      className="mt-auto bg-white pt-8 lg:pt-24"
      aria-labelledby="footer-heading"
    >
      <div className="container">
        <div className="flex flex-col justify-between gap-12 pb-10 lg:flex-row lg:pb-16">
          <div className="flex flex-col gap-6">
            <Image
              src={logo as string}
              width={257}
              height={137}
              alt="Vivaldis Interim logo"
              className="w-[177px] lg:mb-16 lg:w-auto"
            />

            <FooterSocialLinks className="hidden lg:flex" />

            <div className="hidden lg:block">
              <LanguageSwitcher
                locale={locale}
                className="w-auto rounded-[44px] border py-2 pl-5 pr-3"
              />
            </div>

            <FooterLoginSection
              locale={locale}
              className="mt-16 hidden lg:block"
              i18n={{ login_at: t("footer.login_at") }}
            />
          </div>

          <div className="flex gap-12 lg:pt-16">
            <div className="flex flex-col gap-12 lg:gap-16">
              {[
                {
                  audience: AvailableTargetAudience.EMPLOYEE,
                  menu: menuEmployee,
                  alternateMenu: menuEmployer,
                },
                {
                  audience: AvailableTargetAudience.EMPLOYER,
                  menu: menuEmployee,
                  alternateMenu: menuEmployer,
                },
              ].map((item) => (
                <div
                  className="flex flex-col xl:grid xl:grid-cols-2 xl:gap-20"
                  key={item.audience}
                >
                  <h3 className="footer-nav-title">
                    {targetAudience === item.audience
                      ? item.menu?.name
                      : item.alternateMenu?.name}
                  </h3>

                  <FooterNavSection
                    navigation={
                      targetAudience === item.audience
                        ? item.menu
                        : item.alternateMenu
                    }
                    title=""
                  >
                    {/* Add 'Jobs per region' & 'Jobs per sector' to EMPLOYEE link section on mobile */}
                    {item.audience === AvailableTargetAudience.EMPLOYEE && (
                      <>
                        {[menuJobsPerRegion, menuJobsPerSector].map((menu) => (
                          <li className="lg:hidden" key={menu?.name}>
                            <SubNavGroup
                              itemTitle={menu?.name ?? ""}
                              subNav={menu?.items ?? []}
                              subNavClassName="pt-3"
                              itemClassName="text-gray-600 hover:text-gray-900"
                              clickToOpen
                            />
                          </li>
                        ))}
                      </>
                    )}
                  </FooterNavSection>
                </div>
              ))}
            </div>
          </div>

          {menuAbout?.items?.length && (
            <div className="md:grid md:grid-cols-2 md:gap-8 lg:pt-16">
              <FooterNavSection
                title={menuAbout?.name}
                navigation={menuAbout}
              />
            </div>
          )}
        </div>

        <FooterLoginSection
          className="flex items-start gap-20 border-t pb-8 pt-6 sm:items-center lg:hidden"
          i18n={{
            login_at: t("footer.login_at"),
          }}
          locale={locale}
        />

        <div className="flex flex-wrap items-center justify-between gap-4 border-t py-6 lg:hidden">
          <LanguageSwitcher
            locale={locale}
            className="w-auto rounded-[44px] border px-5 py-2"
          />

          <FooterSocialLinks />
        </div>

        <div className="hidden grid-cols-2 gap-8 border-t py-14 lg:grid">
          <FooterNavSection
            navigation={
              targetAudience === AvailableTargetAudience.EMPLOYEE
                ? menuJobsPerRegion
                : menuExpertises
            }
            columns={2}
          />
          <FooterNavSection
            navigation={
              targetAudience === AvailableTargetAudience.EMPLOYEE
                ? menuJobsPerSector
                : menuServices
            }
            columns={2}
          />
        </div>

        <div className="flex flex-col justify-between gap-4 border-t py-6 text-xs lg:py-12 xl:flex-row xl:items-center">
          <span className="whitespace-nowrap text-black">
            &copy; Vivaldis {new Date().getFullYear()}
          </span>

          <div className="flex flex-wrap gap-4 text-black-300">
            <span>
              Vivaldis Interim VG / 609 / U / BUOSA 54-406 54-405 W.INT.511
              DG-LAV-012
            </span>
            <span>Vivaldis Construct VG / 1380 / BC 55-406 W.DISP.900</span>
          </div>

          <div className="flex flex-wrap items-center gap-2 md:gap-4">
            {menuLegal?.items?.map((item) => (
              <Link
                key={item.id}
                href={item.url}
                className="whitespace-nowrap text-black-300 underline transition-colors hover:text-black"
                prefetch={false}
              >
                {item.title}
              </Link>
            ))}
            <a
              href="#"
              className="ot-sdk-show-settings whitespace-nowrap text-black-300 underline transition-colors hover:text-black"
            >
              {t("footer.cookie_settings")}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
