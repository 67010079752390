import Link from "next/link";

import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface SocialLinkProps {
  className?: string;
  type: "twitter" | "linkedin" | "facebook";
  url: string;
}

export const SocialLink = ({ url, className, type }: SocialLinkProps) => {
  return (
    <Link
      href={url}
      className={cn(
        "inline-flex h-12 w-12 shrink-0 items-center justify-center rounded-full border transition-colors hover:bg-grey-100",
        className,
      )}
      title={type}
      prefetch={false}
    >
      <Icon name={type} className="h-4 w-4" />
    </Link>
  );
};
